/* You can add global styles to this file, and also import other style files */
@import 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css';

// Import custom fonts
@font-face {
  font-family: 'DMSans-Variable';
  src: url('/assets/fonts/DMSans-Variable.ttf') format('truetype');
}

@font-face {
  font-family: 'MyriadPro-Regular';
  src: url('/assets/fonts/MyriadPro-Regular.off') format('off');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('/assets/fonts/Montserrat-Regular.off') format('off');
}

* {
  font-family: 'DMSans-Variable', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  align-content: right;
  align-items: right;
  text-align: right;
}

.text-justify {
  text-align: justify;
}

img,
svg {
  vertical-align: middle !important;
}

.align-center {
  align-content: center;
  align-items: center;
}

.justified-center {
  justify-content: center;
}

.form-check-input:checked {
  background-color: #ffc107;
  border-color: #ffc107;
}

[mat-align-tabs='space-between'] > .mat-tab-header .mat-tab-labels {
  justify-content: space-between !important;
}

.mat-tab-label-content {
  font-weight: bolder;
}

.mat-dialog-container {
  border-radius: 24px !important;
}

:host ::ng-deep .mat-dialog-container {
  max-height: 90vh; // El modal no excederá el 90% de la altura de la ventana
  height: auto; // Se ajusta automáticamente al contenido
  overflow-y: auto; // Se activa el scroll vertical si el contenido es mayor que 90vh
}
